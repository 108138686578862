import {AnyAction} from 'redux'
import {PlacedOrderState} from '../types'
import {PLACE_ORDER, UPDATE_ORDER, GET_ORDER, getOrderTicketsDownloadUrl} from '../actions/placed-order'
import {RESERVE_TICKETS} from '../actions/reservation'

const defaultState: PlacedOrderState = {
  order: undefined,
  loadingTicketsUrl: true,
  orderPageUrl: null,
}

export const placedOrder = (state = defaultState, action: AnyAction): PlacedOrderState => {
  switch (action.type) {
    case PLACE_ORDER.SUCCESS:
    case UPDATE_ORDER.SUCCESS:
    case GET_ORDER.SUCCESS:
      return {
        ...state,
        order: action.payload.order,
        ...(action.payload.orderPageUrl ? {orderPageUrl: action.payload.orderPageUrl} : {}),
      }
    case RESERVE_TICKETS.REQUEST:
      return defaultState
    case getOrderTicketsDownloadUrl.pending.toString(): {
      return {...state, loadingTicketsUrl: true}
    }
    case getOrderTicketsDownloadUrl.rejected.toString(): {
      return defaultState
    }
    case getOrderTicketsDownloadUrl.fulfilled.toString(): {
      const {loadingTicketsUrl, downloadTicketsUrl} = action.payload
      return {...state, loadingTicketsUrl, downloadTicketsUrl}
    }
    default:
      return state
  }
}
